<template>
  <div>
    <div class="page_container"><BackComponent :title="$t('profile')" /></div>

    <div class="card_one page_container">
      <div class="profile_icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="20"
          viewBox="0 0 21 20"
          fill="none"
        >
          <path
            d="M14.1667 5.83268C14.1667 7.85773 12.5251 9.49935 10.5 9.49935C8.475 9.49935 6.83337 7.85773 6.83337 5.83268C6.83337 3.80764 8.475 2.16602 10.5 2.16602C12.5251 2.16602 14.1667 3.80764 14.1667 5.83268Z"
            fill="#383636"
            stroke="#383636"
          />
          <path
            d="M17.5743 18.2507H3.4258C3.48063 15.1983 6.54262 12.584 10.5 12.584C14.4575 12.584 17.5195 15.1983 17.5743 18.2507Z"
            fill="#383636"
            stroke="#383636"
          />
        </svg>
      </div>
      <p class="id">Player ID: {{ myProfile.msisdn }}</p>
      <KycStatus :status="status" />
    </div>

    <div class="page_container">
      <div class="input_wrapper">
        <input
          type="text"
          :placeholder="$t('firstName')"
          :aria-label="$t('firstName')"
          name="firstName"
          v-model="firstName"
        />
      </div>
      <div class="input_wrapper">
        <input
          type="text"
          :placeholder="$t('lastName')"
          :aria-label="$t('lastName')"
          name="lastName"
          v-model="lastName"
        />
      </div>
      <div class="input_wrapper phone_number">
        <div class="country">
          <div>
            <CountryFlag />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
            >
              <path
                d="M13.28 5.9668L8.9333 10.3135C8.41997 10.8268 7.57997 10.8268 7.06664 10.3135L2.71997 5.9668"
                stroke="#BAB7B7"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <span>+225</span>
        </div>
        <label for="">{{ $t("phoneNumber") }}</label>
        <input
          type="number"
          aria-label="Enter Amount"
          name="phonenumber"
          v-model="phonenumber"
          readonly
        />
      </div>
      <div class="input_wrapper">
        <input
          type="email"
          :placeholder="$t('emailAddress')"
          :aria-label="$t('emailAddress')"
          name="email"
          v-model="email"
        />
      </div>
      <div class="input_wrapper">
        <label for="dateOfBirth">{{ $t("dob") }} (dd/mm/yyyy)</label>
        <input
          type="date"
          :placeholder="$t('dob') + ' (dd/mm/yyyy)'"
          :aria-label="$t('dob') + ' (dd/mm/yyyy)'"
          name="dateOfBirth"
          v-model="dateOfBirth"
        />
      </div>
      <div style="margin-top: 24px">
        <ChopbetButton
          :disabled="status && status !== -1"
          variant="Variant"
          @click="submitProfileData"
          >{{ $t("save") }}
        </ChopbetButton>
      </div>
    </div>
  </div>
</template>
<script>
import BackComponent from "../../../components/ui/BackComponent.vue";
import CountryFlag from "../../../components/icons/CountryFlag.vue";
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";
import KycStatus from "../../../components/KycStatus.vue";
import instance from "../../../services/identity";

export default {
  name: "Setting",
  components: {
    BackComponent,
    CountryFlag,
    ChopbetButton,
    KycStatus,
  },
  data: function () {
    return {
      fiatCurrency: process.env.VUE_APP_CURRENCY,
      phonenumber: this.getProfile()?.msisdn
        ? String(this.getProfile()?.msisdn).slice(3)
        : "",
      email: this.getProfile()?.email,
      firstName: this.getProfile()?.first_name,
      lastName: this.getProfile()?.last_name,
      dateOfBirth: this.getProfile()?.dob,
      myProfile: this.getProfile(),
      status: null,
    };
  },

  created() {
    var profile = this.getProfile();
    // Check if the user is logged in
    var isLoggedIn = profile && profile.a;

    if (isLoggedIn) {
      this.EventBus.$on("mb8:updated", (mb8balance) => {
        this.receivedMB8Balance = mb8balance;
      });
      this.EventBus.$on("bonus:updated", (bonus) => {
        this.receivedBonus = bonus;
      });
    }
  },
  mounted() {
    this.getKycDetails();
    this.fetchMyBonusBalance();
    this.$store.dispatch("setCurrentPage", "setting");
    var vm = this;

    vm.myProfile = vm.getProfile();
    // console.log("My Profile data", vm.myProfile);
    // this.reloadProfile();

    // this.getBonus();

    if (!vm.myProfile) {
      this.setError("Login", this.$t("pleaseLoginProceed"));
      this.$router.push({ name: "login", params: {} });
      return;
    }

    setInterval(function () {
      vm.myProfile = vm.getProfile();
    }, 1000);

    this.code = vm.myProfile.referral_code;
    // this.link = vm.myProfile.referral_link;
    this.initShareInviteModal();
  },
  methods: {
    getInitials(firstName, lastName) {
      const firstInitial = firstName ? firstName.charAt(0).toUpperCase() : "";
      const lastInitial = lastName ? lastName.charAt(0).toUpperCase() : "";
      return `${firstInitial}${lastInitial}`;
    },
    verifyAccount() {
      this.$router.push("/account-verification");
    },
    getKycDetails() {
      var vm = this;
      var path = "/kycs";

      instance
        .get(path, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data;
          // vm.setSuccess( msg);
          vm.firstName = msg.first_name;
          vm.lastName = msg.last_name;
          vm.email = msg.email;
          vm.dateOfBirth = msg.date_of_birth;
          this.status = msg.status;
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.status) === 401 ||
              parseInt(err.response.data.status) === 428
            ) {
              vm.setError("Session Expired", `${this.$t("sessionExpired")}`);
              vm.logout();
              return;
            } else {
              // vm.setError("Failed", err.response.data.error_message);
              return;
            }
          } else {
            console.log(JSON.stringify(err));
          }
        });
    },
    submitProfileData() {
      const payload = {
        first_name: this.firstName,
        last_name: this.lastName,
        email: this.email,
        date_of_birth: this.dateOfBirth,
        phone_number: `${this.getProfile()?.msisdn}`,
      };
      localStorage.setItem("x_acc_v_payload1", JSON.stringify(payload));
      this.$router.push("/account-verification");
    },
  },

  computed: {
    bal: function () {
      return this.formatCurrency(this.profile.b1);
    },
    bonus: function () {
      return this.formatCurrency(
        this.profile.balance + this.profile.pending_activation
      );
    },
    myBalance: function () {
      return this.formatCurrency(this.$store.state.balance);
    },
    myBonus: function () {
      return this.$store.state.bonus;
    },
    msg: function () {
      return "Karibu Chopbet.ci, Come let's win BIG together.";
    },
    profile: function () {
      return this.myProfile;
    },
  },
  filters: {
    currency: function (amount) {
      if (amount == null) {
        amount = 0;
      }

      return amount.toLocaleString("en-US", {
        style: "currency",
        currency: "CFA",
      });
    },
    formatOdds: function (x) {
      if (x === undefined) {
        return 1;
      }

      return parseFloat(x).toFixed(2);
    },
  },
};
</script>

<style src="./index.css" scoped></style>
